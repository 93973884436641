export const environment = {
    pageName: 'BWA CMS',
    appFlavor: 'bwa',
    production: true,
    appEnv: 'prod',
    googleMapsKey: 'AIzaSyCWxWZeLFR-9NzzSWVuHMxBpEun0VCiuBU',
    apiConfig: {
        projectEndpoint: 'https://bot-api.itxi.aero',
        apiKey: '1c4a3ec4-6f57-41f2-84a5-561632b52c43',
    },
    firebase: {
        apiKey: 'AIzaSyCWxWZeLFR-9NzzSWVuHMxBpEun0VCiuBU',
        authDomain: 'air-botswana.firebaseapp.com',
        databaseURL: 'https://air-botswana.europe-west1.firebasedatabase.app',
        projectId: 'air-botswana',
        storageBucket: 'air-botswana',
        messagingSenderId: '498493875791',
        appId: '1:498493875791:web:7ff60319de1b2c06a12c3a',
        measurementId: 'G-9Y5RRR5KQZ',
    },
    googleAnalyticsTrackId: '',
    rolesWithLoginAccess: [
        {
            role: 'admin',
            route: 'dashboard/content/users',
            node: 'users',
        },
        {
            role: 'power_user',
            route: 'dashboard/content/airports',
            node: 'airports',
        },
        {
            role: 'viewer',
            route: 'dashboard/content/airports',
            node: 'airports',
        },
    ],
    sessionTokenCheckInterval: 300000,
    getAllFlightsListInterval: 300000,
    sessionTokenExtension: true,
    defaultRoute: 'dashboard/content/users',
    enableRandomBackground: false,
    isGoogleAnalyticEnabled: false,
    airportCoordinates: {
        latitude: -24.557686,
        longitude: 25.924244,
        ISO3166_1_Alpha_2: 'US',
    },
    videoCategories: [],
    userTableLanguages: [{ key: 'en', label: 'LANGUAGES.ENGLISH_ONLY' }],
    userTablesRole: [
        { key: 'admin', label: 'ROLES.ADMIN' },
        { key: 'power_user', label: 'ROLES.POWER_USER' },
        {
            key: 'viewer',
            label: 'ROLES.VIEWER',
        },
    ],
    formLanguages: [
        { key: 'en', title: 'LANGUAGES.ENGLISH', label: 'FORMS.EN_LANG' },
        { key: 'es', title: 'LANGUAGES.ESPANOL', label: 'FORMS.ES_LANG' },
    ],
    microsoftSSOClientId: '',
    googleSSOClientId:
        '845186263188-uj7a03r5tuq6falr2ukmcmpbk6emfuud.apps.googleusercontent.com',
    signInOptions: ['password'],
    appCategories: [],
    payment_method: [],
    payment_status: [],
    orientationArray: [],
    containerType: [],
    contentType: [],
    contentItems: [],
    equipmentType: [],
    departureTime: [],
    sectors: [],
    cartItems: [],
};
